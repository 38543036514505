// extracted by mini-css-extract-plugin
export var ArtistDescription = "Invader-module--ArtistDescription--2OHZv";
export var ArtistInfos = "Invader-module--ArtistInfos--5d0Ao";
export var ButtonWrapper = "Invader-module--ButtonWrapper --NU2Kt";
export var CardWrapper = "Invader-module--CardWrapper--D5qaF";
export var CarrouselWrapper2 = "Invader-module--CarrouselWrapper2--IHKkf";
export var Citations = "Invader-module--Citations--7dKxa";
export var DescriptionWrapper = "Invader-module--DescriptionWrapper--Z9JAl";
export var ImageWrapper = "Invader-module--ImageWrapper--eEzbn";
export var LinkWrapper = "Invader-module--LinkWrapper--R5odR";
export var MobileProtrait = "Invader-module--MobileProtrait--QALIE";
export var More = "Invader-module--More--rMrxu";
export var MoreButton = "Invader-module--MoreButton--eF7zL";
export var NameWrapper = "Invader-module--NameWrapper--sGDwZ";
export var PdpWrapper = "Invader-module--PdpWrapper--I8pXN";
export var PhotosWrapper = "Invader-module--PhotosWrapper--ogU96";
export var ProfilWrapper = "Invader-module--ProfilWrapper--AqnkC";
export var TitleWrapper = "Invader-module--TitleWrapper--bLvt3";
export var Wrapper = "Invader-module--Wrapper--nAEzk";